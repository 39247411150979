@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap");
:root {
  --sidebar-flex: 1.5;
  --condiv-flex: 6;
  --fontsize-p: 1.2rem;
  --fontsize-nav: 1.6rem;
  --typing-font: 3rem;
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway";
}

.App {
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

/* .condiv class is for the right side content container*/
.condiv {
  padding: 100px;
  background-color: #f4f5f9;
  flex: var(--condiv-flex);
}

nav {
  padding: 0px 0px 0px 0px;
  flex: var(--sidebar-flex);
  background: rgb(56, 48, 167) 80%;
  /* background: linear-gradient(
    180deg,
    rgb(67, 34, 214) 50%,
    rgba(132, 124, 252, 1) 80%
  ); */
  border-right: 1px solid black;
}

nav ul {
  font-size: var(--fontsize-nav);
  text-align: center;
  list-style-type: none;
  text-decoration: none !important;
  color: white !important;
}

/* nav ul li {
  margin: 40px;
  font-size: var(--fontsize-nav);
  text-align: center;
  list-style-type: none;
  text-decoration: none !important;
  color: white !important;
} */

.navitem:link {
  font-size: var(--fontsize-nav);
  text-align: center;
  list-style-type: none;
  text-decoration: none !important;
  color: white !important;
}

.navitem:visited {
  font-size: var(--fontsize-nav);
  text-align: center;
  list-style-type: none;
  text-decoration: none !important;
  color: white !important;
}

.active {
  background-color: rgb(128, 130, 255);
  /* padding: 15px 0px; */
  /* border-radius: 20%; */
}

.nav-bg {
  height: 100%;
  width: 100%;
}

.nav-bg:hover {
  background-color: rgb(128, 130, 255);
}

.social {
  position: relative;
  margin-top: 20px;
  display: flex;
  bottom: 50px;
}

.icon {
  padding: 30px 10px;
  height: 30px;
  width: 30px;
  color: #9a9b9c;
}

.icon:hover {
  color: #000000;
}

.icon-navbar {
  /* color: white !important; */
  height: 140px;
  width: 140px;
  padding: 23.75px 0px;
}

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.profilepic {
  border-radius: 15%;
  width: 200px;
  margin: 30px;
  border: 1px solid black;
}
.typingeffect {
  font-size: var(--typing-font);
  margin-bottom: 30px;
  margin-top: 15px;
}

a {
  text-decoration: none;
  color: white;
}

/* .pic-bg {
  background: rgb(87, 75, 255) 80%;
  border-radius: 20%;
} */
@media all and (max-width: 450px) {
  :root {
    --fontsize-nav: 1rem;
    --sidebar-flex: 0;
    --typing-font: 2rem;
  }
  .App {
    flex-direction: column;
  }

  /* .sidebar {
    padding: 30px;
    background: rgb(67, 34, 214);
    background: linear-gradient(
      90deg,
      rgb(67, 34, 214) 50%,
      rgba(132, 124, 252, 1) 80%
    );
  } */
  .condiv {
    padding: 50px;
  }
  nav ul {
    display: flex;
  }
  /* nav ul li {
    margin: 10px;
  } */
  .social {
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i {
    padding-right: 0px;
  }
}

.subtopic {
  margin: 10px 10px 30px 10px !important;
}

p {
  font-size: var(--fontsize-p);
}

h1,
h2,
h3,
h4,
p {
  margin: 10px;
}

.widecard {
  border: 0px solid #9a9b9c;
  display: flex;
  margin: 30px 0px 30px 0px;
}
.widecard:hover {
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.container {
  display: flex;
}

.item {
  flex-grow: 1;
  height: 100px;
}

.item + .item {
  margin-left: 2%;
}

.skills ul li {
  list-style-type: none;
  margin: 20px;
}

.contact-social {
  margin-top: 60px;
}
